import React from "react"
import styled from "@emotion/styled"
import tw from "twin.macro"
import { graphql, useStaticQuery } from "gatsby"
import { hCardDataInterface, hCardInterface } from "../@types/components/hCard"

const HCard: React.FC<hCardInterface> = ({ className }) => {
  const data: hCardDataInterface = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            fullName
            profileImage {
              childImageSharp {
                fixed {
                  srcWebp
                }
              }
            }
          }
        }
      }
    `
  )

  const Section = styled.section`
    ${tw`hidden`}
  `

  return (
    <Section
      className={"h-card" + (className !== undefined ? " " + className : "")}
    >
      {data.site.siteMetadata.profileImage !== null ? (
        <img
          className={"u-photo"}
          src={
            data.site.siteMetadata.profileImage.childImageSharp.fixed.srcWebp
          }
          alt={"profile"}
        />
      ) : (
        ""
      )}
      <a
        className={"u-url u-uid p-name"}
        rel={"me"}
        href={data.site.siteMetadata.siteUrl}
      >
        {data.site.siteMetadata.fullName}
      </a>
      <a className={"u-key"} href={"/keys/ryankes.asc"}>
        PGP key
      </a>
      <span className={"p-note"}>I build stuff</span>
      <a className={"u-email"} href={"mailto:ryan@ryankes.eu"}>
        Email
      </a>
      <a className={"u-url"} href={"https://github.com/alrayyes"}>
        Github
      </a>
      <a className={"u-url"} href={"https://twitter.com/alrayyes"}>
        Twitter
      </a>
      <a className={"u-url"} href={"https://last.fm/user/alrayyes"}>
        Last.fm
      </a>
      <a className={"u-url"} href={"https://social.ryankes.eu/@ryan"}>
        Mastodon
      </a>
      <a className={"u-url"} href={"https://matrix.to/#/@ryan:ryankes.eu"}>
        Matrix
      </a>
    </Section>
  )
}

export default HCard
