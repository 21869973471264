import React, { useEffect } from "react"
import { CommentoPropTypes } from "../../@types/components/posts/commento"

const Commento: React.FC<CommentoPropTypes> = ({ id, url }) => {
  const insertScript = (src: string, id: string, parentElement: Element) => {
    const script = window.document.createElement(`script`)
    script.async = true
    script.src = src
    script.id = id
    parentElement.appendChild(script)

    return script
  }

  // Helper to remove scripts from our page
  const removeScript = (id: string, parentElement: Element) => {
    const script = window.document.getElementById(id)
    if (script) {
      parentElement.removeChild(script)
    }
  }

  useEffect(() => {
    // If there's no window there's nothing to do for us
    if (typeof window === "undefined") {
      return
    }
    const document = window.document
    // In case our #commento container exists we can add our commento script
    if (document.getElementById(`commento`)) {
      //url: <your comment url>
      insertScript(`${url}/js/commento.js`, `commento-script`, document.body)
    }

    // Cleanup remove the script from the page
    /* eslint-disable consistent-return */
    return () => removeScript(`commento-script`, document.body)
  }, [id, url])

  return <div id={`commento`} />
}

export default Commento
